import  React, { useState } from 'react'
import { ChevronDown, ChevronRight, Menu, X } from 'lucide-react'
import mockupImage from "./mockup.png";
// Header Component
const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const scrollTo = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' })
    setIsMenuOpen(false);
  }

  return (
    <header className="bg-white shadow-sm">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href="/" className="text-2xl font-bold text-[#0B7A75]">
              Fotos
            </a>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#0B7A75]"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <span className="sr-only">Open menu</span>
              {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
          <nav className="hidden md:flex space-x-10">
            <a href="#" className="text-base font-medium text-gray-500 hover:text-gray-900">
              Home
            </a>
            <button onClick={
              () => scrollTo('features')
            } className="text-base font-medium text-gray-500 hover:text-gray-900">
              Features
            </button>
            {/* <a href="#" className="text-base font-medium text-gray-500 hover:text-gray-900">
              Pricing
            </a> */}
            <a href="tel:9010529965" className="text-base font-medium text-gray-500 hover:text-gray-900">
              Contact
            </a>
          </nav>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            {/* <a href="#" className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
              Login
            </a> */}
            <a
              href="https://getwaitlist.com/waitlist/14564"
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-[#0B7A75] hover:bg-[#096460]"
            >
              Start Free Trial
            </a>
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <a href="/" className="text-2xl font-bold text-[#0B7A75]">
                    Fotos
                  </a>
                </div>
                <div className="-mr-2">
                  <button
                    type="button"
                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#0B7A75]"
                    onClick={() => setIsMenuOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <X className="h-6 w-6" />
                  </button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
                    Home
                  </a>
                  <button onClick={
                      () => scrollTo('features')
                    } className="text-base font-medium text-gray-900 hover:text-gray-700">
                    Features
                  </button>
                  {/* <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
                    Pricing
                  </a> */}
                  <a href="tel:9010529965" className="text-base font-medium text-gray-900 hover:text-gray-700">
                    Contact
                  </a>
                </nav>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div>
                <a
                  href="https://getwaitlist.com/waitlist/14564"
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-[#0B7A75] hover:bg-[#096460]"
                >
                  Start Free Trial
                </a>
                <p className="mt-6 text-center text-base font-medium text-gray-500">
                  Existing customer?{' '}
                  <a href="#" className="text-[#0B7A75] hover:text-[#096460]">
                    Login
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </header>
  )
}

// Hero Section Component
const HeroSection = () => (
  <div className="relative bg-white overflow-hidden">
    <div className="max-w-7xl mx-auto">
      <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
        <svg
          className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
          fill="currentColor"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          aria-hidden="true"
        >
          <polygon points="50,0 100,0 50,100 0,100" />
        </svg>
        <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
          <div className="sm:text-center lg:text-left">
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <span className="block xl:inline">Revolutionize Your Photography</span>{' '}
              <span className="block text-[#0B7A75] xl:inline">Workflow with AI</span>
            </h1>
            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
              Capture, Edit, and Deliver Stunning Photos in Record Time
            </p>
            <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
              <div className="rounded-md shadow">
                <a
                  href="https://getwaitlist.com/waitlist/14564"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-[#0B7A75] hover:bg-[#096460] md:py-4 md:text-lg md:px-10"
                >
                  Start Free Trial
                </a>
              </div>
              <div className="mt-3 sm:mt-0 sm:ml-3">
                <a
                  href="https://cal.com/alphaxhq/fotos-product-demo"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-[#0B7A75] bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10"
                >
                  Schedule Demo
                </a>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
      <img
        className="h-56 w-full object-contain sm:h-72 md:h-96 lg:w-full lg:h-full"
        src={mockupImage}
        alt="Photographer using a laptop"
        width={1920}
        height={1080}
      />
    </div>
  </div>
)

// Key Features Section Component
const KeyFeaturesSection = () => (
  <div id="features" className="py-12 bg-white">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="lg:text-center">
        <h2 className="text-base text-[#0B7A75] font-semibold tracking-wide uppercase">Features</h2>
        <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Powerful Tools for Photographers
        </p>
        <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
          Streamline your workflow and enhance your creativity with our AI-powered features.
        </p>
      </div>

      <div className="mt-10">
        <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-10">
          {[
            {
              name: 'Lightning-Fast Image Transfer',
              description: 'Transfer high-resolution images in seconds, not minutes.',
              icon: (
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                </svg>
              ),
            },
            {
              name: 'AI-Powered Editing',
              description: 'Let our AI enhance your photos with intelligent adjustments.',
              icon: (
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                  />
                </svg>
              ),
            },
            {
              name: 'Branded Client Galleries',
              description: 'Create stunning, customized galleries for your clients.',
              icon: (
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                  />
                </svg>
              ),
            },
          ].map((feature) => (
            <div key={feature.name} className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-[#0B7A75] text-white">
                  {feature.icon}
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  </div>
)

// How It Works Section Component
const HowItWorksSection = () => (
  <div className="bg-gray-50 py-12">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="lg:text-center">
        <h2 className="text-base text-[#0B7A75] font-semibold tracking-wide uppercase">Process</h2>
        <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          How Fotos Works
        </p>
        <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
          Our streamlined process makes photo management a breeze.
        </p>
      </div>

      <div className="mt-10">
        <div className="flex flex-col md:flex-row justify-center items-center space-y-8 md:space-y-0 md:space-x-8">
          {[
            { step: 1, title: 'Connect your DSLR', icon: '📷' },
            { step: 2, title: 'AI processes and edits photos', icon: '🤖' },
            { step: 3, title: 'Create personalized client galleries', icon: '🖼️' },
            { step: 4, title: 'Deliver stunning results', icon: '🚀' },
          ].map((item) => (
            <div key={item.step} className="flex flex-col items-center">
              <div className="flex items-center justify-center h-16 w-16 rounded-full bg-[#0B7A75] text-white text-2xl">
                {item.icon}
              </div>
              <h3 className="mt-4 text-lg font-medium text-gray-900">Step {item.step}</h3>
              <p className="mt-2 text-base text-gray-500 text-center">{item.title}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
)

// Benefits Section Component
const BenefitsSection = () => (
  <div className="py-12 bg-white">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="lg:text-center">
        <h2 className="text-base text-[#0B7A75] font-semibold tracking-wide uppercase">Benefits</h2>
        <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Why Choose Fotos?
        </p>
        <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
          Experience the advantages of our AI-powered photography workflow.
        </p>
      </div>

      <div className="mt-10">
        <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-10">
          {[
            {
              name: 'Speed Up Your Workflow by 10x',
              description: 'Save time with automated processes and AI-assisted editing.',
              icon: (
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                </svg>
              ),
            },
            {
              name: 'Enhance Your Creative Output',
              description: 'Focus on capturing moments while AI handles the technical details.',
              icon: (
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                  />
                </svg>
              ),
            },
            {
              name: 'Delight Your Clients',
              description: 'Deliver high-quality results faster, increasing client satisfaction.',
              icon: (
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
                  />
                </svg>
              ),
            },
          ].map((benefit) => (
            <div key={benefit.name} className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-[#0B7A75] text-white">
                  {benefit.icon}
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{benefit.name}</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">{benefit.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  </div>
)

// Testimonials Section Component
const TestimonialsSection = () => (
  <section className="py-12 bg-gray-50 overflow-hidden md:py-20 lg:py-24">
    <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="relative">
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            Hear from Our Happy Customers
          </h2>
          <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
            Discover how Fotos has transformed the workflow of photographers around the world.
          </p>
        </div>
        <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {[
            {
              name: 'Emily Johnson',
              role: 'Wedding Photographer',
              image: '/placeholder.svg',
              quote:
                'Fotos has revolutionized my wedding photography business. The AI-powered editing saves me hours of work, allowing me to focus on capturing beautiful moments.',
              rating: 5,
            },
            {
              name: 'Michael Chen',
              role: 'Portrait Photographer',
              image: '/placeholder.svg',
              quote:
                "The client galleries feature is a game-changer. My clients love the professional look, and it's so easy to use. Fotos has truly elevated my business.",
              rating: 5,
            },
            {
              name: 'Sarah Thompson',
              role: 'Nature Photographer',
              image: '/placeholder.svg',
              quote:
                "As a nature photographer, I'm often in remote locations. Fotos' fast image transfer and AI editing have been invaluable in streamlining my workflow on the go.",
              rating: 4,
            }
          ].map((testimonial) => (
            <div key={testimonial.name} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
              <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                  <p className="text-sm font-medium text-[#0B7A75]">Testimonial</p>
                  <div className="block mt-2">
                    <p className="text-xl font-semibold text-gray-900">{testimonial.name}</p>
                    <p className="mt-3 text-base text-gray-500">{testimonial.quote}</p>
                  </div>
                </div>
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <img className="h-10 w-10 rounded-full" src={testimonial.image} alt="" width={40} height={40} />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">{testimonial.name}</p>
                    <p className="text-sm text-gray-500">{testimonial.role}</p>
                  </div>
                </div>
                <div className="mt-3 flex items-center">
                  {[...Array(5)].map((_, i) => (
                    <svg
                      key={i}
                      className={`h-5 w-5 ${i < testimonial.rating ? 'text-yellow-400' : 'text-gray-300'}`}
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>
)

// Pricing Section Component
const PricingSection = () => (
  <div id="pricing" className="bg-white py-12">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:flex-col sm:align-center">
        <h2 className="text-3xl font-extrabold text-gray-900 sm:text-center">Pricing Plans</h2>
        <p className="mt-5 text-xl text-gray-500 sm:text-center">
          Choose the perfect plan for your photography needs
        </p>
      </div>
      <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0">
        {[
          {
            name: 'Basic',
            price: '$29',
            description: 'Perfect for hobbyist photographers',
            features: ['100 AI-powered edits per month', 'Basic client galleries', '24/7 support'],
          },
          {
            name: 'Pro',
            price: '$79',
            description: 'Ideal for professional photographers',
            features: [
              'Unlimited AI-powered edits',
              'Advanced client galleries',
              'Priority 24/7 support',
              'Custom branding',
            ],
          },
          {
            name: 'Enterprise',
            price: 'Custom',
            description: 'For large photography studios',
            features: [
              'All Pro features',
              'Dedicated account manager',
              'Custom AI model training',
              'API access',
            ],
          },
        ].map((tier) => (
          <div
            key={tier.name}
            className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200"
          >
            <div className="p-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">{tier.name}</h3>
              <p className="mt-4 text-sm text-gray-500">{tier.description}</p>
              <p className="mt-8">
                <span className="text-4xl font-extrabold text-gray-900">{tier.price}</span>
                {tier.name !== 'Enterprise' && <span className="text-base font-medium text-gray-500">/month</span>}
              </p>
              <a
                href="#"
                className={`mt-8 block w-full bg-[#0B7A75] border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-[#096460] ${
                  tier.name === 'Enterprise' ? 'hover:bg-[#096460]' : ''
                }`}
              >
                {tier.name === 'Enterprise' ? 'Contact Sales' : 'Start free trial'}
              </a>
            </div>
            <div className="pt-6 pb-8 px-6">
              <h4 className="text-sm font-medium text-gray-900 tracking-wide uppercase">What's included</h4>
              <ul className="mt-6 space-y-4">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex space-x-3">
                    <svg
                      className="flex-shrink-0 h-5 w-5 text-green-500"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="text-sm text-gray-500">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
)

// FAQ Section Component
const FAQSection = () => {
  const [openQuestion, setOpenQuestion] = useState(null)

  const faqs = [
    {
      question: 'How does the AI-powered editing work?',
      answer:
        'Our AI analyzes your photos and applies intelligent adjustments to enhance color, contrast, and composition. It learns from professional editing techniques to deliver stunning results.',
    },
    {
      question: 'Can I customize the AI editing to match my style?',
      answer:
        'Yes! You can train the AI on your editing style by providing sample edits. The more you use Fotos, the better it adapts to your preferences.',
    },
    {
      question: 'Is my data secure with Fotos?',
      answer:
        'Absolutely. We use industry-standard encryption and security measures to protect your photos and client information. Your data is always yours and never used for any other purpose.',
    },
    {
      question: 'How does the free trial work?',
      answer:
        'Our free trial gives you full access to all Fotos features for 14 days. No credit card required. You can upgrade to a paid plan at any time during or after the trial.',
    },
  ]

  return (
    <div className="bg-gray-50 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
          <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Frequently asked questions
          </h2>
          <dl className="mt-6 space-y-6 divide-y divide-gray-200">
            {faqs.map((faq, index) => (
              <div key={faq.question} className="pt-6">
                <dt className="text-lg">
                  <button
                    className="text-left w-full flex justify-between items-start text-gray-400"
                    onClick={() => setOpenQuestion(openQuestion === index ? null : index)}
                  >
                    <span className="font-medium text-gray-900">{faq.question}</span>
                    <span className="ml-6 h-7 flex items-center">
                      {openQuestion === index ? (
                        <ChevronDown className="h-6 w-6" aria-hidden="true" />
                      ) : (
                        <ChevronRight className="h-6 w-6" aria-hidden="true" />
                      )}
                    </span>
                  </button>
                </dt>
                {openQuestion === index && (
                  <dd className="mt-2 pr-12">
                    <p className="text-base text-gray-500">{faq.answer}</p>
                  </dd>
                )}
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

// Call-to-Action Section Component
const CTASection = () => (
  <div className="bg-[#0B7A75]">
    <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
      <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
        <span className="block">Ready to Revolutionize Your Photography Workflow?</span>
      </h2>
      <p className="mt-4 text-lg leading-6 text-[#E0F2F1]">
        Join thousands of photographers who are saving time and enhancing their creativity with Fotos.
      </p>
      <div className="mt-8 flex justify-center">
        <div className="inline-flex rounded-md shadow">
          <a
            href="https://getwaitlist.com/waitlist/14564"
            className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-[#0B7A75] bg-white hover:bg-[#E0F2F1]"
          >
            Start Your Free Trial Now
          </a>
        </div>
        <div className="ml-3 inline-flex">
          <a
            href="#"
            className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-[#096460] hover:bg-[#085450]"
          >
            Contact Sales
          </a>
        </div>
      </div>
    </div>
  </div>
)

// Footer Component
const Footer = () => (
  <footer className="bg-white">
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
      <div className="flex justify-center space-x-6 md:order-2">
        <a href="#" className="text-gray-400 hover:text-gray-500">
          <span className="sr-only">Facebook</span>
          <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
            <path
              fillRule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clipRule="evenodd"
            />
          </svg>
        </a>

        <a href="#" className="text-gray-400 hover:text-gray-500">
          <span className="sr-only">Instagram</span>
          <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
            <path
              fillRule="evenodd"
              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
              clipRule="evenodd"
            />
          </svg>
        </a>

        <a href="#" className="text-gray-400 hover:text-gray-500">
          <span className="sr-only">Twitter</span>
          <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
          </svg>
        </a>
      </div>
      <div className="mt-8 md:mt-0 md:order-1">
        <p className="text-center text-base text-gray-400">
          &copy; {new Date().getFullYear()} Fotos, Inc. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
)

// Main Component
export default function LandingPage() {
  return (
    <div className="bg-white">
      <Header />
      <main>
        <HeroSection />
        <KeyFeaturesSection />
        <HowItWorksSection />
        <BenefitsSection />
        <TestimonialsSection />
        {/* <PricingSection /> */}
        <FAQSection />
        <CTASection />
      </main>
      <Footer />
    </div>
  )
}